<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Code"
              v-model="currentProjectDetail.project.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Owner</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Owner"
              v-model="currentProjectDetail.project.owner"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Name"
              v-model="currentProjectDetail.project.name"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Contract Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Contract Number"
              v-model="currentProjectDetail.project.contract_no"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="currentProjectDetail.project.status"
              :options="options.statuses"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Leader</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="currentProjectDetail.project.leader"
              placeholder="Project Leader"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
          <b-button variant="success" @click="simpan()" ref="kt_simpan_project">
            <i class="flaticon2-check-mark"></i> Save
          </b-button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <b-tabs content-class="mt-3">
          <b-tab title="Stasiun">
            <station
              :stations="projectStation"
              @setStations="setStations"
            ></station>
          </b-tab>

          <b-tab title="Project Personnel">
            <project-personnel
              :pics="projectDocon"
              @setPics="setPics"
            ></project-personnel>
          </b-tab>

          <b-tab title="Stakeholder">
            <company
              :companies="projectCompany"
              @setCompanies="setCompanies"
            ></company>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Station from "./edit-components/Station.vue";
import ProjectPersonnel from "./edit-components/ProjectPersonnel.vue";
import Company from "./edit-components/Company.vue";
import { GET_COMPANY } from "@/core/services/store/company.module";
import { GET_PIC } from "@/core/services/store/pic.module";
import { GET_STATION } from "@/core/services/store/station.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_PROJECT_DETAIL_BY_ID,
  POST_PROJECT_DETAIL,
} from "@/core/services/store/project.module";
import { mapGetters } from "vuex";

export default {
  name: "EditDetailProject",
  components: {
    Station,
    ProjectPersonnel,
    Company,
  },

  data() {
    return {
      form: {
        status: "",
        name: "",
        code: "",
        owner: "",
        contractNumber: "",
        commander: null,
      },
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
      },
      data: {
        projects: [
          {
            id: 1,
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN",
          },
        ],
        stations: [],
        pics: [],
        companies: [],
      },
    };
  },
  methods: {
    toEditPage(data) {
      this.$router.push({
        name: "edit-detail-project",
        params: {
          id: data.id,
        },
      });
    },

    setStations(stations) {
      this.data.stations = stations;
    },

    setPics(pics) {
      this.data.pics = pics;
    },

    setCompanies(companies) {
      this.data.companies = companies;
    },

    simpan() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_simpan_project"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.currentProjectDetail.project_station = this.projectStation;
      this.currentProjectDetail.project_company = this.projectCompany;
      this.currentProjectDetail.project_docon = this.projectDocon;

      setTimeout(() => {
        this.$store
          .dispatch(POST_PROJECT_DETAIL, this.currentProjectDetail)
          .then(() =>
            this.$store.dispatch(
              GET_PROJECT_DETAIL_BY_ID,
              this.$route.params.id
            )
          );

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },

    init() {
      this.$route.params.id;
    },
  },

  mounted() {
    //this.init();

    this.$store.dispatch(GET_STATION, {
      params: {
        id: this.$route.params.id,
      },
    });

    this.$store.dispatch(GET_PIC);

    this.$store.dispatch(GET_COMPANY);

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Edit Project Detail" }]);

    this.$store.dispatch(GET_PROJECT_DETAIL_BY_ID, this.$route.params.id);
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentProjectDetail",
      "projectStation",
      "projectDocon",
      "projectCompany",
    ]),
  },
};
</script>
