<template>
  <div>
    <div class="d-flex my-3 justify-content-start">
      <button class="btn btn-success btn-md" @click="add()">
        <i class="flaticon-add"></i> Add Personnel
      </button>
    </div>

    <div class="table-responsive mt-5">
      <table class="table table-head-custom table-head-bg table-borderless">
        <thead>
          <tr class="text-left">
            <th class="pl-7" style="min-width: 250px">
              <span class="text-dark-75">PIC</span>
            </th>
            <th style="min-width: 100px">Jobdesk</th>
            <th style="min-width: 100px">Action</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(pic, i) in pics">
            <tr v-bind:key="i">
              <td class="pl-0">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ getFullPic(pic.code) }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <v-app>
                    <v-autocomplete
                      v-model="pic.code"
                      :items="picOptions"
                      @change="updatePicData(pic, i)"
                      color="white"
                      :filter="customFilterPIC"
                      item-text="text"
                      dense
                      small-chips
                      solo
                    ></v-autocomplete>
                  </v-app>
                </template>
              </td>

              <td class="pl-0">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ getJobDeskName(pic.jobdesk) }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <v-app>
                    <v-autocomplete
                      v-model="pic.jobdesk"
                      :items="options.jobDesks"
                      color="white"
                      :filter="customFilter"
                      item-text="text"
                      dense
                      small-chips
                      solo
                    ></v-autocomplete>
                  </v-app>
                </template>
              </td>

              <td class="pl-0">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = i"
                  v-if="currentIndex !== i"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = null"
                  v-if="currentIndex === i"
                >
                  <i class="flaticon2-check-mark"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="remove(pic, i)"
                >
                  <i class="flaticon2-trash"></i>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DELETE_PROJECT_PIC } from "@/core/services/store/project.module";
export default {
  name: "ProjectPersonnel",
  props: {
    pics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pic: {
        id: null,
        station: null,
      },
      currentIndex: null,
      options: {
        pics: [],
        jobDesks: [
          {
            text: "Docon Design",
            value: "design",
          },
          {
            text: "Coordinator",
            value: "koor",
          },
          {
            text: "Docon Interface",
            value: "interface",
          },
          {
            text: "Drafter",
            value: "drafter",
          },
          {
            text: "PPC",
            value: "planner",
          },
          {
            text: "Coordinator",
            value: "koor",
          },
          {
            text: "Enginer",
            value: "enginer",
          },
          {
            text: "Manager",
            value: "manager",
          },
          {
            text: "Admin",
            value: "admin",
          },
          {
            text: "Director",
            value: "director",
          },
          {
            text: "Executive Vice Precident",
            value: "evp",
          },
          {
            text: "Vice Precident",
            value: "vp",
          },

        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.pics.map((x) => {
        if (x.code == null) {
          return x;
        }
        var getName = this.picOptions.find((z) => z.value === x.code).name;
        x.name = getName;
        return x;
      });
      this.$emit("setPics", this.pics);
    }, 1500);
  },
  methods: {
    customFilterPIC(item, queryText) {
      const textOne = item.text.toLowerCase();
      const textTwo = item.value.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const textTwo = item.value.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    remove(pic, i) {
      if (pic?.id) {
        const deletePics = [];

        deletePics.push(pic);

        this.$store
          .dispatch(DELETE_PROJECT_PIC, { docon: deletePics })
          .then(() => {
            const pics = this.pics;

            pics.splice(i, 1);

            this.$emit("setPics", pics);
          });
      } else {
        const pics = this.pics;

        pics.splice(i, 1);

        this.$emit("setPics", pics);
      }
    },

    add() {
      const pics = this.pics;

      pics.push({
        id: null,
        station: null,
      });

      this.$emit("setPics", pics);
    },

    update(index, station) {
      const pics = this.pics;
      pics[index] = station;
      this.$emit("setPics", pics);
    },
    updatePicData(pic, index) {
      this.pics[index].name = this.picOptions.find(
        (x) => x.value === pic.code
      ).name;
    },
    getFullPic(code) {
      var result = this.picOptions.find((x) => x.value === code);
      if (result !== undefined) return result.text;

      return "";
    },

    getJobDeskName(code) {
      var result = this.options.jobDesks.find((x) => x.value === code);

      if (result !== undefined) return result.text;

      return "";
    },
  },
  computed: {
    ...mapGetters(["currentPics"]),
    picOptions() {
      return this.currentPics?.map((pic) => {
        return {
          value: pic.code,
          name: pic.name,
          text: pic.code + "-" + pic.name,
        };
      });
    },
  },
};
</script>
