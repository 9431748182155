<template>
  <div>
    <div class="d-flex my-3 justify-content-start">
      <button class="btn btn-success btn-md " @click="add()">
        <i class="flaticon-add"></i> Add Station
      </button>
    </div>

    <div class="table-responsive mt-5 ">
      <table class="table table-head-custom table-head-bg table-borderless">
        <thead>
          <tr class="text-left">
            <th class="pl-7" style="min-width: 250px">
              <span class="text-dark-75">Station</span>
            </th>
            <th style="min-width: 100px">Action</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(station, i) in stations">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ getFullStationName(station.code) }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <v-app>
                    <v-autocomplete
                      v-model="station.code"
                      :items="stationOptions"
                      color="white"
                      :filter="customFilter"
                      item-text="text"
                      dense
                      small-chips
                      solo
                    ></v-autocomplete>
                  </v-app>
                </template>
              </td>

              <td class="pl-0 ">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = i"
                  v-if="currentIndex !== i"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = null"
                  v-if="currentIndex === i"
                >
                  <i class="flaticon2-check-mark"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="remove(station, i)"
                >
                  <i class="flaticon2-trash"></i>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { DELETE_PROJECT_STATION } from "@/core/services/store/project.module";
export default {
  name: "Station",
  props: {
    stations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      station: {
        id: null,
        station: null
      },
      currentIndex: null,
      options: {
        stations: []
      }
    };
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const textTwo = item.value.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    remove(station, i) {
      if (station?.id) {
        const deleteStations = [];

        deleteStations.push(station);

        this.$store
          .dispatch(DELETE_PROJECT_STATION, { station: deleteStations })
          .then(() => {
            const stations = this.stations;

            stations.splice(i, 1);

            this.$emit("setStations", stations);
          });
      } else {
        const stations = this.stations;

        stations.splice(i, 1);

        this.$emit("setStations", stations);
      }
    },

    add() {
      const stations = this.stations;

      stations.push({
        id: null,
        station: null
      });

      this.$emit("setStations", stations);
    },

    update(index, station) {
      const stations = this.stations;
      stations[index] = station;
      this.$emit("setStations", stations);
    },

    getFullStationName(code) {
      const result = this.stationOptions?.find(x => x.value === code);

      if (result !== undefined) return result.text;

      return "";
    }
  },
  computed: {
    ...mapGetters(["currentStations", "projectStation"]),

    stationOptions() {
      return this.currentStations.data?.map(station => {
        return {
          value: station.code,
          text: station.code + "-" + station.name
        };
      });
    }
  }
};
</script>
