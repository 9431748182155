<template>
  <div>
    <div class="d-flex my-3 justify-content-start">
      <button class="btn btn-success btn-md" @click="add()">
        <i class="flaticon-add"></i> Add Stakeholder
      </button>
    </div>

    <div class="table-responsive mt-5">
      <table class="table table-head-custom table-head-bg table-borderless">
        <thead>
          <tr class="text-left">
            <th style="min-width: 150px">
              <span class="text-dark-75">Stakeholder</span>
            </th>
            <th style="min-width: 100px">Action</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(company, i) in companies">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ getFullCompanyName(company.code) }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <v-app>
                    <v-autocomplete
                      v-model="company.code"
                      :items="companyOptions"
                      color="white"
                      :filter="customFilter"
                      item-text="text"
                      dense
                      small-chips
                      solo
                    ></v-autocomplete>
                  </v-app>
                </template>
              </td>

              <td class="pl-0 ">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = i"
                  v-if="currentIndex !== i"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = null"
                  v-if="currentIndex === i"
                >
                  <i class="flaticon2-check-mark"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm"
                  @click="remove(company, i)"
                >
                  <i class="flaticon2-trash"></i>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { DELETE_PROJECT_COMPANY } from "@/core/services/store/project.module";

export default {
  name: "Company",

  props: {
    companies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      company: {
        id: null,
        company: null,
      },
      currentIndex: null,
      options: {
        companies: [],
      },
    };
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.text.toLowerCase();
      const textTwo = item.value.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    remove(company, i) {
      if (company?.id) {
        const deleteCompany = [];

        deleteCompany.push(company);

        this.$store
          .dispatch(DELETE_PROJECT_COMPANY, { company: deleteCompany })
          .then(() => {
            const companies = this.companies;

            companies.splice(i, 1);

            this.$emit("setPics", companies);
          });
      } else {
        const companies = this.companies;

        companies.splice(i, 1);

        this.$emit("setPics", companies);
      }
    },

    add() {
      const companies = this.companies;

      companies.push({
        id: null,
        station: null,
      });

      this.$emit("setCompanies", companies);
    },

    update(index, company) {
      const companies = this.companies;

      companies[index] = company;

      this.$emit("setCompanies", companies);
    },

    getFullCompanyName(code) {
      var result = this.companyOptions.find((x) => x.value === code);
      if (result !== undefined) return result.text;

      return "";
    },
  },

  computed: {
    ...mapGetters(["currentCompanies"]),
    companyOptions() {
      return this.currentCompanies.map((cmp) => {
        return {
          value: cmp.code,
          text: cmp.code + "-" + cmp.name,
        };
      });
    },
  },
};
</script>
